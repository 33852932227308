<template>
    <a-modal
        :confirmLoading="load"
        :title="modalTitle"
        :visible="show"
        @ok="addCard"
        :okText="modalOkText"
        :centered="true"
        @cancel="onClose"
        :width="800"
    >
        <a-form-model
            ref="paymentMethod"
            :model="paymentMethod"
            class="profile"
        >
            <a-row>
                <a-col :span="24">
                    <a-row :gutter="16">
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                prop="cardNo"
                                :class="{
                                    'valid-card': numberValidation.isValid,
                                    'valid-type':
                                        numberValidation.isPotentiallyValid,
                                }"
                                label="Credit Card Number"
                                required
                                :rules="req('Please enter the Card Number')"
                            >
                                <a-input
                                    v-model="paymentMethod.cardNo"
                                    placeholder="0000 - 0000 - 0000 - 0000"
                                    :maxLength="16"
                                    size="large"
                                    required
                                    :disabled="action !== 'add'"
                                >
                                </a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                prop="cardName"
                                label="Cardholder Name"
                                required
                                :rules="req('Please enter the Cardholder Name')"
                            >
                                <a-input
                                    v-model="paymentMethod.cardName"
                                    placeholder="Cardholder Name"
                                    size="large"
                                    required
                                >
                                </a-input>
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                label="Expiry Date"
                                required
                                :rules="req('Please enter the Expiry Date')"
                                prop="expiry"
                            >
                                <a-input
                                    size="large"
                                    v-model="paymentMethod.expiry"
                                    placeholder="MM/YY"
                                    :maxLength="5"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                label="CCV/CVV Code"
                                required
                                :rules="req('Please enter the CCV/CVV code')"
                                prop="cvv"
                            >
                                <a-input
                                    size="large"
                                    v-model="paymentMethod.cvv"
                                    placeholder="XXX"
                                    :maxLength="4"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
            <hr />
            <a-row>
                <a-col :span="24">
                    <a-row :gutter="16">
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                label="Address"
                                required
                                prop="address.one"
                                :rules="req('Please enter the Address')"
                            >
                                <a-input
                                    v-model="paymentMethod.address.one"
                                    placeholder="Address"
                                    size="large"
                                    required
                                >
                                </a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 24 : 12">
                            <a-form-model-item
                                label="Country"
                                required
                                prop="address.country"
                                :rules="req('Please enter the Country')"
                            >
                                <a-select
                                    show-search
                                    placeholder="Choose a country"
                                    size="large"
                                    option-filter-prop="children"
                                    :filter-option="filterOption"
                                    v-model="paymentMethod.address.country"
                                    @change="
                                        paymentMethod.address.region = null
                                    "
                                >
                                    <a-select-option value="US">
                                        United States
                                    </a-select-option>
                                    <a-select-option value="CA">
                                        Canada
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="$mq == 'sm' ? 24 : 8">
                            <a-form-model-item
                                label="City"
                                required
                                prop="address.city"
                                :rules="req('Please enter the City')"
                            >
                                <a-input
                                    v-model="paymentMethod.address.city"
                                    placeholder="City"
                                    size="large"
                                    required
                                >
                                </a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 24 : 8">
                            <a-form-model-item
                                label="State/Province"
                                required
                                :rules="req('Please enter the Province/State')"
                                prop="address.region"
                            >
                                <a-select
                                    show-search
                                    placeholder="Choose a state/province"
                                    size="large"
                                    option-filter-prop="children"
                                    :filter-option="filterOption"
                                    v-model="paymentMethod.address.region"
                                >
                                    <template
                                        v-if="
                                            paymentMethod.address.country ===
                                            'US'
                                        "
                                    >
                                        <a-select-option
                                            v-for="(opt, optI) in states"
                                            :key="opt.name + optI"
                                            :value="opt.name"
                                        >
                                            {{ opt.name }}
                                        </a-select-option>
                                    </template>
                                    <template v-else>
                                        <a-select-option
                                            v-for="(opt, optI) in provinces"
                                            :key="opt.name + optI"
                                            :value="opt.name"
                                        >
                                            {{ opt.name }}
                                        </a-select-option>
                                    </template>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 24 : 8">
                            <a-form-model-item
                                label="Zip/Postal Code"
                                required
                                :rules="req('Please enter the Postal Code')"
                                prop="address.zipcode"
                            >
                                <a-input
                                    size="large"
                                    v-model="paymentMethod.address.zipcode"
                                    placeholder="Zip/Postal Code"
                                />
                            </a-form-model-item>
                        </a-col>
                    </a-row>
					<a-row :gutter="16">
						<a-col :span="24">
							<a-form-model-item>
                                <a-checkbox
									v-model="paymentMethod.default"
								>
									Make this card default for all projects
								</a-checkbox>
                            </a-form-model-item>
						</a-col>
					</a-row>
                </a-col>
            </a-row>
        </a-form-model>
        <template slot="footer">
            <div class="dF aC" style="gap: 400px">
                <div class="pull-left">
                    <img src="@/assets/all-cards.png" width="150px" alt="all-cards" />
                </div>
                <div class="pull-right">
                    <a-button type="default" @click="onClose">
                        CANCEL
                    </a-button>

                    <a-button type="primary" :loading="load" @click="addCard">
                        {{ modalOkText }}
                    </a-button>
                </div>
            </div>
        </template>
    </a-modal>
</template>

<script>
import valid from "card-validator";

export default {
    props: {
        show: {
            default: false,
            type: Boolean,
        },
        load: {
            default: false,
            type: Boolean,
        },
        action: {
            default: "add",
            type: String,
        },
        modalTitle: {
            default: "",
            type: String,
        },
        modalOkText: {
            default: "",
            type: String,
        },
        states: {
            default: () => [
                { name: "Alabama", value: "AL" },
                { name: "Alaska", value: "AK" },
                { name: "Arizona", value: "AZ" },
                { name: "Arkansas", value: "AR" },
                { name: "California", value: "CA" },
                { name: "Colorado", value: "CO" },
                { name: "Connecticut", value: "CT" },
                { name: "Delaware", value: "DE" },
                { name: "District of Columbia", value: "DC" },
                { name: "Florida", value: "FL" },
                { name: "Georgia", value: "GA" },
                { name: "Hawaii", value: "HI" },
                { name: "Idaho", value: "ID" },
                { name: "Illinois", value: "IL" },
                { name: "Indiana", value: "IN" },
                { name: "Iowa", value: "IA" },
                { name: "Kansas", value: "KS" },
                { name: "Kentucky", value: "KY" },
                { name: "Louisiana", value: "LA" },
                { name: "Maine", value: "ME" },
                { name: "Maryland", value: "MD" },
                { name: "Massachusetts", value: "MA" },
                { name: "Michigan", value: "MI" },
                { name: "Minnesota", value: "MN" },
                { name: "Mississippi", value: "MS" },
                { name: "Missouri", value: "MO" },
                { name: "Montana", value: "MT" },
                { name: "Nebraska", value: "NE" },
                { name: "Nevada", value: "NV" },
                { name: "New Hampshire", value: "NH" },
                { name: "New Jersey", value: "NJ" },
                { name: "New Mexico", value: "NM" },
                { name: "New York", value: "NY" },
                { name: "North Carolina", value: "NC" },
                { name: "North Dakota", value: "ND" },
                { name: "Ohio", value: "OH" },
                { name: "Oklahoma", value: "OK" },
                { name: "Oregon", value: "OR" },
                { name: "Pennsylvania", value: "PA" },
                { name: "Rhode Island", value: "RI" },
                { name: "South Carolina", value: "SC" },
                { name: "South Dakota", value: "SD" },
                { name: "Tennessee", value: "TN" },
                { name: "Texas", value: "TX" },
                { name: "Utah", value: "UT" },
                { name: "Vermont", value: "VT" },
                { name: "Virginia", value: "VA" },
                { name: "Washington", value: "WA" },
                { name: "West Virginia", value: "WV" },
                { name: "Wisconsin", value: "WI" },
                { name: "Wyoming", value: "WY" },
            ],
            type: Array,
        },
        provinces: {
            default: () => [
                { name: "Alberta", value: "AB" },
                { name: "British Columbia", value: "BC" },
                { name: "Manitoba", value: "MB" },
                { name: "New Brunswick", value: "NB" },
                { name: "Newfoundland and Labrador", value: "NL" },
                { name: "Nova Scotia", value: "NS" },
                { name: "Ontario", value: "ON" },
                { name: "Prince Edward Island", value: "PE" },
                { name: "Quebec", value: "QC" },
                { name: "Saskatchewan", value: "SK" },
                { name: "Northwest Territories", value: "NT" },
                { name: "Nunavut", value: "NU" },
                { name: "Yukon", value: "YT" },
            ],
            type: Array,
        },
        paymentMethodData: {
            default: () => ({}),
            type: Object,
        },
    },

    data() {
        return {
            paymentMethod: {
                ccBrand: "",
                cardNo: "",
                expiry: "",
                cardName: "",
                cvv: "",
				default: false,
                address: {
                    one: "",
                    two: "",
                    region: "",
                    city: "",
                    country: "US",
                    zipcode: "",
                },
            },
            numberValidation: {
                card: {
                    niceType: "",
                    type: "",
                    patterns: [4],
                    gaps: [4, 8, 12],
                    lengths: [16, 18, 19],
                    code: { name: "CVV", size: 3 },
                    matchStrength: 1,
                },
                isPotentiallyValid: false,
                isValid: false,
            },
        };
    },
    watch: {
        paymentMethod: {
            handler(newCard) {
                let { cardNo } = newCard;
                let nums = valid.number(cardNo);
                this.numberValidation = nums;
                // if (!nums.isPotentiallyValid) {
                // } else {
                //     this.numberValidation = nums
                // }
            },
            deep: true,
        },
        numberValidation: {
            handler(newCard) {
                let table = {
                    express: "amex",
                    mastercard: "mc",
                    visa: "visa",
                };
                if (
                    newCard.isPotentiallyValid &&
                    newCard.card &&
                    newCard.card.niceType
                ) {
                    let ccBrand = newCard.card.niceType.toLowerCase();
                    let foundKey = Object.keys(table).find((x) =>
                        ccBrand.includes(x)
                    );
                    if (foundKey) {
                        let filename = table[foundKey];
                        this.paymentMethod.ccBrand = filename;
                    } else {
                        this.paymentMethod.ccBrand = "";
                    }
                } else {
                    this.numberValidation.src = require(`@/assets/cc/error.png`);
                }
            },
            deep: true,
        },
        show: {
            handler(val) {
                if (!val) {
                    this.$refs.paymentMethod.resetFields();
                }
            },
        },
    },
    created() {
        this.paymentMethod =
            this.paymentMethodData && Object.keys(this.paymentMethodData).length
                ? this.paymentMethodData
                : this.paymentMethod;
    },
    methods: {
        onClose() {
            this.$refs.paymentMethod.resetFields();
            this.$emit("close");
        },
        req: (msg) => ({ required: true, message: msg }),
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },
        addCard() {
            this.$refs.paymentMethod.validate((valid2) => {
                if (valid2) {
                    let expiry = valid.expirationDate(
                        this.paymentMethod.expiry
                    );
                    if (this.action === "add" && !this.numberValidation.isValid)
                        return this.$message.error(
                            "Card not Valid. Please try again."
                        );
                    if (!expiry.isValid)
                        return this.$message.error(
                            "Card Expiry not Valid. Please try again."
                        );
                    let sendObj = JSON.parse(
                        JSON.stringify(this.paymentMethod)
                    );

                    this.$emit("cardPayment", sendObj);
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" module>
</style>

<style lang="scss">
</style>
